import { IMatchStats, ITeam } from "~/utils/api.interfaces.enums";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "~/utils/theme-provider";
import Chart from "~/components/charts/chart";

type Props = {
  activeTeam?: ITeam;
  matches?: IMatchStats[];
  showXLabels?: boolean;
};

export default function ChartMiniSpectator({ activeTeam, matches }: Props) {
  const dateOptions = {
    // weekday: "long",
    // year: "2-digit",
    month: "short",
    day: "numeric",
  };
  const dateFormatter = (params: any) => {
    return new Date(params).toLocaleDateString("en", dateOptions);
  };
  const { t, ready, i18n } = useTranslation("translation");

  function sortByDateAsc(a, b) {
    return new Date(a.scheduledAtUtc) - new Date(b.scheduledAtUtc);
  }
  const componentMatches = matches?.slice(0); //make a clone of the array
  const [sortedMatches, setSortedMatches] = useState<IMatchStats[]>(
    componentMatches?.sort(sortByDateAsc)
  );

  useEffect(() => {
    if (activeTeam && matches) {
      setSortedMatches(componentMatches?.sort(sortByDateAsc));
    }
  }, [matches]);
  const [theme] = useTheme();
  return (
    <div className="w-full text-gray-900">
      <Chart
        options={{
          colors: ["#D7503D"],
          chart: {
            height: 100,
            type: "area",
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            sparkline: {
              enabled: false,
            },
          },
          grid: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            curve: "smooth",
            lineCap: "butt",

            width: [3, 0],
            dashArray: 0,
          },

          yaxis: {
            opposite: true,
            labels: { show: false },
          },
          xaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            type: "category",
            categories: sortedMatches,
            labels: {
              show: false,
              formatter: function (value, opts) {
                if (value) {
                  const label: string[] = [
                    dateFormatter(value.scheduledAtUtc) + " - ",
                    value.opponent?.organization?.shortName,
                    value.score?.team + " - " + value.score?.opponent,
                  ];
                  return label;
                }
              },
              style: { colors: theme === "dark" ? "#fff" : "#000" },
            },
            tooltip: {
              enabled: false,
            },
          },
          tooltip: {
            marker: {
              show: true,
            },
            x: {
              show: true,
            },
          },
        }}
        series={[
          {
            name: t("statsPages.graphTitleSpectators"),
            data: matches?.map((m: IMatchStats) => m.viewsCount) || [],
          },
        ]}
        type="line"
        width="100%"
      />
    </div>
  );
}
