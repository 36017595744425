import { Link, useParams } from "@remix-run/react";

import { useTranslation } from "react-i18next";
import { IMatchStats, ITeam, ITotals } from "~/utils/api.interfaces.enums";
import { MixPanel } from "~/utils/MixPanel";

export default function WinRatio({
  matches,
  totals,
  activeTeam,
}: {
  matches: IMatchStats[];
  activeTeam?: ITeam;
  totals: ITotals;
}) {
  const params = useParams();

  const dateOptions = {
    // weekday: "long",
    // year: "2-digit",
    month: "short",
    day: "numeric",
  };
  const dateFormatter = (params: any) => {
    return new Date(params).toLocaleDateString("en", dateOptions);
  };
  const { t, ready, i18n } = useTranslation("translation");

  if (matches?.length === 0) {
    totals.totalMatchesWon = 3;
    totals.totalMatchesDraw = 1;
    totals.totalMatchesLost = 2;
  }
  return (
    <>
      <div className="card ">
        <div className={`card-body p-6`}>
          <h2 className="font-bold text-xl pb-0">
            {t("statsPages.titleMatchWinRatio")}
          </h2>
          <h3 className="text-sm font-normal text-gray-400 dark:text-gray-200">
            {t("statsPages.titleTotalShare")}
          </h3>
          <div
            className={`${
              matches?.length === 0 ? "opacity-10 pointer-events-none" : ""
            }`}
          >
            <h4 className="text-5xl font-bold">
              {matches?.length > 0 &&
                Math.round(
                  (100 / matches?.length || 0) * totals?.totalMatchesWon
                )}
              {matches?.length > 0 && (
                <span className="text-sm font-normal text-gray-400 dark:text-gray-200">
                  %
                </span>
              )}
            </h4>
            <div className="flex justify-between items-end gap-4 mt-3">
              <div className="font-bold text-xl">
                <h3 className="text-xs font-normal text-gray-400 dark:text-gray-200 w-8">
                  {t("statsPages.titleTotalWin")}
                </h3>
                {totals?.totalMatchesWon}
              </div>
              <progress
                className="progress progress-primary w-full h-3 mb-1.5 ml-5"
                value={totals?.totalMatchesWon}
                max={matches?.length || 0}
              ></progress>
            </div>
            <div className="flex justify-between items-end gap-4 mt-3">
              <div className="font-bold text-xl">
                <h3 className="text-xs font-normal text-gray-400 dark:text-gray-200 w-8">
                  {t("statsPages.titleTotalDraw")}
                </h3>
                {totals?.totalMatchesDraw}
              </div>
              <progress
                className="progress progress-primary w-full h-3 mb-1.5 ml-5"
                value={totals?.totalMatchesDraw}
                max={matches?.length || 0}
              ></progress>
            </div>
            <div className="flex justify-between items-end gap-4 mt-3">
              <div className="font-bold text-xl">
                <h3 className="text-xs font-normal text-gray-400 dark:text-gray-200 w-8">
                  {t("statsPages.titleTotalLost")}
                </h3>
                {totals?.totalMatchesLost}
              </div>
              <progress
                className="progress progress-primary w-full h-3 mb-1.5 ml-5"
                value={totals?.totalMatchesLost}
                max={matches?.length || 0}
              ></progress>
            </div>
          </div>
          {matches?.length === 0 && (
            <div className="right-0 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              {" "}
              <div className="font-bold text-2xl text-center mt-8 dark:text-gray-200">
                No data
              </div>
              <div className="text-center text-gray-500 dark:text-gray-200 text-sm mb-10 max-w-xs m-auto">
                Use the match feature in the Mingle Sport App to generate this
                statistics graph
              </div>
            </div>
          )}
          {matches?.length > 0 && (
            <Link
              to={`/activities/match/`}
              className="btn btn-default btn-outline mt-auto"
              onClick={(event) => {
                MixPanel.track(
                  "goalsScoredDetail_teamStats_tap_button_moreInfo"
                );
              }}
            >
              {t("statsPages.buttonMoreInfo")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="h-2 w-2 ml-auto"
                viewBox="0 0 8 12"
              >
                <path
                  fill="currentColor"
                  d="m1.686.122 6.132 5.46a.53.53 0 0 1 0 .8l-6.132 5.46a.558.558 0 0 1-.777 0l-.748-.8a.54.54 0 0 1 0-.77l4.367-3.89a.53.53 0 0 0 0-.8L.201 1.732a.54.54 0 0 1 0-.77l.748-.8a.558.558 0 0 1 .737-.04Z"
                />
              </svg>
            </Link>
          )}
        </div>
      </div>
    </>
  );
}
